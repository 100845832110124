import "./selectfield.css";
import Select, { components } from "react-select";
import React from "react";
import { arrowdownIcon } from "../../assets/images/icons";

export default function Selectfield({
  width,
  height,
  placeholder,
  icon,
  hiddenDropdownIndicator,
  changePlaceHolderStyle,
  noIcon,
}) {
  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    // Add more options here
  ];

  let DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <img src={arrowdownIcon} alt="dorp down" />
    </components.DropdownIndicator>
  );

  if (hiddenDropdownIndicator) {
    DropdownIndicator = () => null;
  }

  let ValueContainer = ({ children, ...props }) => {
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          {!!children && (
            <img
              src={icon}
              style={{ position: "absolute", left: 20, marginTop: 2 }}
              alt="icon"
            />
          )}
          {children}
        </components.ValueContainer>
      )
    );
  };
  if (!noIcon) {
  }
  const styles = {
    valueContainer: (base) => ({
      ...base,
      paddingLeft: 60,
      minWidth: width,
      height: height,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    control: (base) => ({
      ...base,
      // margin: '0 1rem',
    }),
    menu: (base) => ({ ...base, width: width }),
  };

  if (changePlaceHolderStyle) {
    styles.placeholder = (base) => ({
      ...base,
      color: "#0F0E0E",
      fontSize: "14px",
      fontFamily: "Poppins",
      fontWeight: "400",
    });
  }

  if (noIcon) {
    const styles = {
      valueContainer: (base) => ({
        ...base,
        paddingLeft: 10,
        height: height,
      }),
      indicatorSeparator: (base) => ({
        ...base,
        display: "none",
      }),
      control: (base) => ({
        ...base,
        width: "270px !important",
        margin: "0 0rem",
      }),
      menu: (base) => ({
        ...base,
        width: "270px !important",
      }),
    };
    if (changePlaceHolderStyle) {
      styles.placeholder = (base) => ({
        ...base,
        color: "#0F0E0E",
        fontSize: "14px",
        fontFamily: "Poppins",
        fontWeight: "400",
      });
    }
    return (
      <Select
        options={options}
        components={{ DropdownIndicator }}
        styles={styles}
        placeholder={placeholder}
      />
    );
  }
  return (
    <Select
      options={options}
      components={icon?{ ValueContainer, DropdownIndicator }:{DropdownIndicator}}
      styles={styles}
      placeholder={placeholder}
    />
  );
}
