import {
  arrawrightIcon,
  calendarIcon,
  locationIcon,
  logowhiteIcon,
  longrightarrowIcon,
} from "../../assets/images/icons";
import Carousel from "../../components/Carousel/Carousel";
import Navbar from "../../components/Navbar/Navbar";
import Topbar from "../../components/Topbar/Topbar";
import Travelcard from "../../components/Travelcard/Travelcard";
import "./home.css";
import {
  hotelcard_image,
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  travelcard_image,
  travelcard_image2,
} from "../../assets/images/images";

import React from "react";
import Cardscarousel from "../../components/Cardscarousel/Cardscarousel";
import Hotelcard from "../../components/Hotelcard/Hotelcard";
import Serviceslist from "../../components/Serviceslist/Serviceslist";
import Footer from "../../components/Footer/Footer";
import Partners from "../../components/Partners/Partners";

export default function Home() {
  return (
    <div className="Page_home">
      <Topbar />
      <Navbar page={"accueil"} />
      <Carousel />
      <div className="holder">
        <div className="search">
          <div className="search_input">
            <img src={locationIcon} />
            <input
              type="text"
              name="search"
              placeholder="where to ? i.e Bordeaux, France"
            />
            <div className="calendar">
              <img src={calendarIcon} />
            </div>
          </div>
          <button>Recherche</button>
        </div>
      </div>
      <div className="p1">
        <div className="header">
          <div className="title">
            <p>Destinations</p>
          </div>
          <div className="filters">
            <div className="filter">
              <p>Promotion</p>
            </div>
            <div className="filter selected">
              <p>Nouveau</p>
              <div className="border_bottom"></div>
            </div>
          </div>
        </div>
        <div className="grid">
          <Travelcard isVarient={false} image={travelcard_image} />
          <Travelcard isVarient={false} image={travelcard_image} />
          <Travelcard isVarient={false} image={travelcard_image} />
          <Travelcard isVarient={false} image={travelcard_image} />
          <Travelcard isVarient={true} image={travelcard_image2} />
          <Travelcard isVarient={true} image={travelcard_image2} />
          <Travelcard isVarient={true} image={travelcard_image2} />
          <Travelcard isVarient={true} image={travelcard_image2} />
        </div>
        <div className="button">
          <button>Voir Plus</button>
        </div>
      </div>
      <div className="p2">
        <div className="left_side">
          <p className="title">à propos de l'agence ProTour</p>
          <p className="text">
            PROTOUR est une agence de voyages & de tourisme crée en 2023, dont
            le siège est situé au niveau de la wilaya de Setif.
          </p>
          <button>Plus sur ProTour</button>
        </div>
        <div className="right_side">
          <div className="logo">
            <img src={logowhiteIcon} alt="logo" />
          </div>
        </div>
      </div>
      <div className="p1">
        <div className="header">
          <div className="title">
            <p>Voyges organises Recommandes</p>
          </div>
        </div>
        <div className="slider">
          <Cardscarousel />
        </div>
      </div>
      <div className="p1">
        <div className="header">
          <div className="title">
            <p>Hôtels Partenaires les mieux notés</p>
          </div>
          <div className="redirect">
            <p>Voir tout</p>
            <img src={longrightarrowIcon} alt="voire tous" />
          </div>
        </div>
        <div className="hotelscards">
          <Hotelcard isVarient={false} image={travelcard_image}/>
          <Hotelcard isVarient={false} image={travelcard_image}/>
          <Hotelcard isVarient={false} image={travelcard_image}/>
          <Hotelcard isVarient={false} image={travelcard_image}/>

        </div>
      </div>
      <Partners/>
      <Serviceslist/>
      <Footer/>
    </div>
  );
}
