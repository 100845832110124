import React from "react";
import SwiperCore from "swiper";
import { Navigation, Pagination,Autoplay } from 'swiper/modules';
import "./carousel.css"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { image1 } from "../../assets/images/carousel";

SwiperCore.use([Navigation, Pagination,Autoplay]);


const images = [
    {
      src: "https://picsum.photos/id/1018/800/600",
      alt: "Image 1",
      text: "Text 1",
    },
    {
      src: "https://picsum.photos/id/1015/800/600",
      alt: "Image 2",
      text: "Text 2",
    },
    {
      src: "https://picsum.photos/id/1019/800/600",
      alt: "Image 3",
      text: "Text 3",
    },
  ];

export default function Carousel() {
    return (
        <Swiper
        direction="vertical"
        pagination={{ clickable: true, el: '.swiper-pagination', type: 'bullets' }}
        style={{ width: '100%', height: '600px' }}     
        autoplay={{ delay: 3000 }} >
        <SwiperSlide>
        <div className="slide_cn" style={{ position: 'relative' }}>
          <img src={image1} alt="Image 1" style ={{width: "100%", height: "100%", objectFit:"cover"}} />
          <p className="img_text img_title" >Voyage organisé à <span className="blue_text">Dubai</span></p>
          <p className="img_text img_disc" >Lorem ipsum dolor sit amet, consectetur adipiscing elit, <br/>sed do eiusmod tempor incididunt ut.</p>
          <p className="img_text img_price" >170 000 DZD / per seulement</p>
          <p className="img_text img_duration" >pendant 15 jours</p>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="slide_cn" style={{ position: 'relative' }}>
          <img src={image1} alt="Image 1" style ={{width: "100%", height: "100%", objectFit:"cover"}} />
          <p className="img_text img_title" >Voyage organisé à <span className="blue_text">Dubai</span></p>
          <p className="img_text img_disc" >Lorem ipsum dolor sit amet, consectetur adipiscing elit, <br/>sed do eiusmod tempor incididunt ut.</p>
          <p className="img_text img_price" >170 000 DZD / per seulement</p>
          <p className="img_text img_duration" >pendant 15 jours</p>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="slide_cn" style={{ position: 'relative' }}>
          <img src={image1} alt="Image 1" style ={{width: "100%", height: "100%", objectFit:"cover"}} />
          <p className="img_text img_title" >Voyage organisé à <span className="blue_text">Dubai</span></p>
          <p className="img_text img_disc" >Lorem ipsum dolor sit amet, consectetur adipiscing elit, <br/>sed do eiusmod tempor incididunt ut.</p>
          <p className="img_text img_price" >170 000 DZD / per seulement</p>
          <p className="img_text img_duration" >pendant 15 jours</p>
        </div>
        </SwiperSlide>
        <div className="swiper-pagination" style={{ left: 0, marginLeft: '20px' }} />      </Swiper>
      );
}
